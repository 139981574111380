.annonce {
    background-color: rgb(213, 213, 213);
   
    border: 2px #000000 double;
  
    padding: 16px;
    .info {
        text-align: left;
        height: 150px;
        padding-left: 160px
    }

    .banner {
        float: left;
        width: 160px;
        height: 168px;
    }
}
