.PussyFinder {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
  cursor: pointer;
  img {
    max-width: 250px;
  }
}
