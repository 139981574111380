.annonce {
    position: relative;
    min-width: 200px;

    a {
        text-decoration: none;

        .banner {
            margin-right: 10px;
        }
        .info {
            margin: 10px;
            line-height: 10px;

            .title {
                color: blue;
                line-height: 16px;
            }

            .price {
                background-color: yellow;
                padding: 3px;
                font-weight: bold;
            }
            .description {
                line-height: 18px;
                color: rgb(40, 40, 40);
                font-style: italic;
            }
        }
    }
}
