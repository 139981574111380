.plugins {
  .plugins-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    div:first-child {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #ff3301;
      border-top: 0.5rem solid #ff3301;
      border-bottom: 0.5rem solid #ff3301;
      font-family: Boogaloo;
      text-transform: uppercase;
    }
    .white-color {
      color: white;
    }
    .yellow-color {
      color: yellow;
    }
    div:first-child * {
      margin: 0.1rem 0;
    }
    div:first-child:hover {
      background-color: #669900;
      border-top: 0.5rem solid #669900;
      border-bottom: 0.5rem solid #669900;
      cursor: pointer;
    }
    .warning-message {
      background-color: white;
      font-size: 0.8rem;
      font-family: verdana, helvetica, arial;
      padding: 0.5rem 0;
    }
  }
}
