.Header {
  position: relative;
  overflow: hidden;
  .Banner {
    width: 100%;
    height: 300px;
  }
  .Title {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 65px;
    width: 100%;
    color: white;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
}
