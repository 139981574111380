.PubGrosseSanglier {
  font-family: verdana, helvetica, arial;
  padding: 3px;
  border: 2px black solid;
  background-color: white;
  .grosse {
    width: 100%;
    height: 200px;
    border: 2px black solid;
  }
  .Title {
    display: flex;
    img {
      width: 40px;
      margin-left: 4px;
    }
  }
}
