.App {
  text-align: center;
  background-image: url("assets/back.gif");
 
  .ColumnPubLeft {
    position: absolute;
    width: 250px;
    left: 30px;
    z-index: 1000;
    top: 200px;
  }
  .ColumnPubRight {
    position: absolute;
    width: 250px;

    right: 30px;
    top: 200px;
  }
  .Content {
    max-width: 1200px;
    margin: auto;
    .Column {
      display: flex;
      .Page {
        background-color: white;
        width: 100%;
      }
    }
  }
}
