.Menu {
  width: 30%;
  background-color: brown;
  .title {
    background-color: chartreuse;
    padding: 5px;
  }
  li {
    a {
      color: white;
    }
    margin-top: 20px;
    color: white;
    text-align: left;
  }
}
.monimage{
  width: 100%;
}
html {overflow-x: hidden}
@media only screen and (max-width: 800px) {
  .column-mobile{
    display: block!important;
  }
  .mobile-hide{
    display: none;
  }
  .montableau-mobile{
    display: block;
    list-style: none;
  }
  .Menu {
    width: 100%;
    background-color: brown;
    .title {
      background-color: chartreuse;
      padding: 5px;
    }
    li {
      a {
        color: white;
      }
      margin-top: 20px;
      color: white;
      text-align: left;
    }
  }
}