.PubLeft {
    font-family: verdana, helvetica, arial;
    padding: 3px;
    border: 2px black solid;
    background-color: white;
    width: 300px;
    .bichons {
        width: 100%;
        height: 200px;
        border: 2px black solid;

        .bichon {
            height: 200px;
        }
    }
}
