.montableau {
    .monimage {
        width: 250px;
        height: 250px;
    }
    .monimage2 {
        width: 250px;
    }
    table,
    th,
    td {
        border: 1px solid;
    }
}
